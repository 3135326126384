import React, { useEffect, useState } from 'react';
import anime from 'animejs';
import Navigation from './components/navigation.component';
import './styles/style.scss';
import { scrollingTop, scrollToTop } from './utils/handyFunctions';
import classnames from 'classnames';
import logo from './assets/images/LM Logo.png';
import { ArrowUpward } from '@material-ui/icons';
import { Switch, Route, Link, useHistory } from 'react-router-dom';
import Specifications from './components/specifications.component';
import Home from './components/home.component';
import AGB from './components/agb.component';
import Impressum from './components/impressum.component';
import Privacy from './components/privacy.component';
import Services from './components/services.component';

function App() {
  const [loading] = useState(false);
  const [notHome, setNotHome] = useState(false);
  const [goToSection, setGoToSection] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname !== '/') {
      setNotHome(true);
    } else {
      const scrollElement =
        window.document.scrollingElement ||
        window.document.body ||
        window.document.documentElement;
      scrollElement.scrollTo(0, 0);
    }
    history.listen((location, action) => {
      if (history.location.pathname !== '/') {
        setNotHome(true);
      } else {
        setNotHome(false);
        document.querySelector('.go-up').classList.remove('visible');
        document
          .querySelector('.go-up')
          .removeEventListener('click', scrollingTop);
        document.removeEventListener('scroll', scrollToTop);
      }
    });
    anime({
      targets: '.next-section',
      easing: 'easeInOutQuad',
      scale: 1.1,
      loop: true,
      direction: 'alternate',
      duration: 1000,
    });
    anime({
      targets: '.next-section > path',
      easing: 'easeInOutQuad',
      translateY: 2,
      loop: true,
      direction: 'alternate',
      duration: 500,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classnames({ 'hide-scrollbar': loading })}>
      <div className={classnames('loading', { hide: !loading })}>
        <img id="logo-load" src={logo} alt="Lohnmüle logo" />
        {loading && <h3>Website wird geladen...</h3>}
      </div>
      <div className={classnames('main', { hide: loading })}>
        <Navigation
          isNotHome={notHome}
          toSection={(id) => {
            setGoToSection(id);
          }}
        />
        <Switch>
          <Route exact path="/">
            <Home
              loaded={(val) => {
                const scrollElement =
                  window.document.scrollingElement ||
                  window.document.body ||
                  window.document.documentElement;

                if (goToSection && val) {
                  scrollElement.scrollTop =
                    document.getElementById(goToSection).offsetTop;
                }
              }}
            />
          </Route>
          <Route
            path="/leistungsverzeichnis"
            component={Specifications}
          />
          <Route path="/agb" component={AGB} />
          <Route path="/impressum" component={Impressum} />
          <Route path="/datenschutz" component={Privacy} />
          <Route path="/pkc-leistungsverzeichnis" component={Services} />
        </Switch>
        {!notHome && (
          <ArrowUpward
            className="go-up next-section"
            data-target="hero"
            type="button"
          />
        )}
        {notHome && (
          <ArrowUpward className="go-up next-section" type="button" />
        )}
      </div>
      <footer>
        <div className="logos">
          <img src={logo} alt="Lohnmühle" />
          <p>Lohnmühle</p>
        </div>
        <div className="links">
          <Link to="/impressum">Impressum</Link>
          <Link to="/datenschutz">Datenschutz</Link>
          <Link to="/agb">AGB</Link>
        </div>
        <span>
          &copy;{new Date().getFullYear()} Lohnmühle GmbH alle Rechte
          vorbehalten.
        </span>
      </footer>
    </div>
  );
}

export default App;
