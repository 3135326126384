import React, { useEffect } from 'react';
import image from '../assets/images/transparente-preise.png';
import DownloadIcon from '../assets/icons/download.svg';
import ServicesFile from '../assets/documents/Leistungsverzeichnis-2025.pdf';

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section id="services">
        <div className="container">
            <div className="text">
                <h1 className="title"> Transparente Preise und effiziente Abrechnung </h1>
                <p className="subtitle">
                  Als ETL-Kanzlei unterstützen wir Sie bei der Abrechnung Ihrer Mandanten 
                  mit unserem umfassenden PKC-Leistungsverzeichnis. Entdecken Sie die 
                  transparenten Preise und den detaillierten Leistungsumfang, die Ihnen 
                  helfen, Ihre Abrechnungsprozesse zu optimieren. Laden Sie jetzt unser 
                  PDF herunter, um alle Informationen zu erhalten und von unseren Dienstleistungen zu profitieren!
                </p>
                <div className="btn-grouping">
                    <a href={ServicesFile} download="Leistungsverzeichnis-2025.pdf" className="btn btn-primary">
                        PDF herunterladen <img src={DownloadIcon} className="button-icon" alt="Download-Symbol"/>
                    </a>
                    <a href="/" className="btn btn-secondary">
                        Erkunden Sie unsere Website
                    </a>
                  </div>
                <p className="note">
                  *Bitte beachten Sie: Die Lohmühle rechnet ausschließlich Digitallöhne ab. Der Einsatz 
                  einer <a href="https://www.edtime.de/" target="_blank">edtime</a> Ausbaustufe (HR, edtime, edtime Plus) 
                  sowie die Nutzung von PISA sind daher zwingend erforderlich.
                </p>
            </div>
            <img className="image" src={image} alt="Transparente preise"/>
        </div>
    </section>
  );
};

export default Services;